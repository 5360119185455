import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { ErrorMessages } from '../../components/Common/Constants';
import { ITaxingAuthority } from '../../core/domain/models/ITaxingAuthority';
import { ICommonDataViewModel, initialCommonData } from '../../core/domain/viewModels/ICommonDataViewModel';
import { StatusCode } from 'src/core/domain/models/Organizer/Organizer';

interface RequestTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_REQUEST;
    id: string;
}

interface ResponseTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_RESPONSE;
    data: ITaxingAuthority[];
}

interface SetCoverPageAction {
    type: actionTypes.SET_IS_COVER_PAGE;
    isCoverPage: boolean;
}
interface SetUserDataAction {
    type: actionTypes.SET_USER_DATA;
    userData: any;
}
interface SetClientDataAction {
    type: actionTypes.SET_CLIENT_INFO_DATA;
    userData: any;
}

interface FailureTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_FAILURE;
    id: string;
}

export interface IUteSettings {
    uteEnabled: boolean;
    uteModified: string;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseTaxingAuthorityAction
    | RequestTaxingAuthorityAction
    | FailureTaxingAuthorityAction
    | SetCoverPageAction
    | SetUserDataAction
    | SetClientDataAction
    ;

export const actionCreators = {

    requestAllTaxingAuthorities: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.TAXING_AUTHORITY_REQUEST, id: id });
        return initializeAxios().get<ITaxingAuthority[]>('/api/Helper/GetAllTaxingAuthorityAsync/' + id)
            .then(function (response: AxiosResponse<ITaxingAuthority[]>) {

                dispatch({
                    type: actionTypes.TAXING_AUTHORITY_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.TaxingAuthorityError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXING_AUTHORITY_FAILURE, id: id });
            });
    },

    setIsCoverPage: (isCoverPage: boolean): AppThunkAction<any> => (dispatch, getState) => {
        dispatch({ type: actionTypes.SET_IS_COVER_PAGE, isCoverPage })
    },

    getWalkMeScript: (id: string, callback: (script: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get<string>(`/api/Helper/GetWalkMeScript/` + id)
            .then(function (response: AxiosResponse<string>) {
                callback(response.data);
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response?.statusText : `getWalkMeScriptAsync failed with error ${error.message}`,
                    statusType: StatusType.Error

                });
            });
    },
    getHeaderData: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get<any>('/api/ApplicationInsight/BasicDetails/' + id)
            .then(function (response: AxiosResponse<any>) {
                dispatch({ type: actionTypes.SET_USER_DATA, userData: response.data })
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response?.statusText : `getWalkMeScriptAsync failed with error ${error.message}`,
                    statusType: StatusType.Error
                });
            });
    },
    getClientInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get<any>('/api/OrganizerClient/GetClient/' + id)
            .then(function (response: AxiosResponse<any>) {
                dispatch({ type: actionTypes.SET_CLIENT_INFO_DATA, userData: response.data })
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response?.statusText : `getWalkMeScriptAsync failed with error ${error.message}`,
                    statusType: StatusType.Error
                });
            });
    },
    getOneHubRedirectUrl: (id: string, callback: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get<string>(`/api/OneHub/onehub-redirect-url/` + id)
            .then(function (response: AxiosResponse<string>) {
                callback(response.data);
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response?.statusText : `getOneHubRedirectUrl failed with error ${error.message}`,
                    statusType: StatusType.Error
                });
            });
    },
    getUTESettings: (clientId: string, callback?: (data: IUteSettings) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get<IUteSettings>('/api/Document/ute-setting/' + clientId)
            .then(function (response: AxiosResponse<IUteSettings>) {
                callback && callback(response.data);
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response?.statusText : `getUTESettings failed with error ${error.message}`,
                    statusType: StatusType.Error
                });
            });
    }

}

export const reducer: Reducer<ICommonDataViewModel> = (state: ICommonDataViewModel = initialCommonData, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.TAXING_AUTHORITY_REQUEST:
            var received = { ...state };
            received.taxingAuthorities = [];
            return received;
        case actionTypes.TAXING_AUTHORITY_RESPONSE:
            var received = { ...state };
            received.taxingAuthorities = action.data;
            return received;
        case actionTypes.SET_IS_COVER_PAGE:
            const { isCoverPage } = action;
            return { ...state, isCoverPage }
        case actionTypes.SET_USER_DATA:
            const stateData = { ...state }
            stateData.profileData = action.userData
            return stateData;
        case actionTypes.SET_CLIENT_INFO_DATA:
            const data = { ...state }
            data.clientData = action.userData
            return data;
        default:
            return state || initialCommonData;
    }
};